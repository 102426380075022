import React from 'react';
import { Link } from 'react-router-dom';



export default function Header() {
  

    return(
        <header>
        
   
         <h4 className='headerLink'> <Link to="/">CV</Link>  </h4>
         <h4 className='headerLink'> <Link to="/projects">Projects</Link> </h4>
         <h4 className='headerLink'> <Link to="/about">About</Link> </h4>
       

        </header>
    )
}