import React from "react";



export default function Blockchain() {




    return(
<div className="course2" style={{display:"flex"}}>


        <div style={{maxWidth:"500px", paddingLeft:"25%"}}>
            <img className="tech" src="tech/blockchain (2).png" alt="Hardhat"/>
            <img className="tech" src="tech/blockchain (6).png" alt="Ethereum"/>
            <img className="tech" src="tech/blockchain (5).png" alt="blockchain"/>
            <img className="tech" src="tech/blockchain (3).png" alt="Solidity"/>
            <img className="tech" src="tech/blockchain (1).png" alt="IC"/>
            <img className="tech" src="tech/blockchain (4).png" alt="NFT"/>

        </div>


        <div className="course3">
        <h3>Solidity, Hardhat, Ethers, Motoko, React</h3>
        <li><b>Solidity</b> <a href="https://github.com/SamBorisov/Solidity" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
             <p>Theory, explaining, syntax, gas, keywords and more</p>
        <li><b>US Election</b> <a href="https://github.com/SamBorisov/USElection" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
             <p>Submissions from states and picks winner (Solidity)</p>
        <li><b>Lime Token</b> <a href="https://github.com/SamBorisov/LimeToken" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
              <p>Token creation and Wrapping Ether functionality</p>
        <li><b>React Web3</b> <a href="https://github.com/SamBorisov/React-Web3" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
            <p>Borderplate with wallet connection and abi</p>
        <li><b>Library</b> <a href="https://github.com/SamBorisov/Library" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
            <p>Allows you to borrow and return books</p>
        <li><b>To-Do IC</b> <a href="https://github.com/SamBorisov/ToDo---IC-React" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
            <p>To-do with Motoko that saves notes</p>
        <li><b>DBank IC</b> <a href="https://github.com/SamBorisov/DBank-IC" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
            <p>Token and faucet with Motoko</p>   

      </div>


</div>
    )
}