import React from "react";



export default function PersonalSkills() {




    return(
<div>

    <img className="sun" src="Mars4.png" alt="Mars"/>

          <h3>Languages:</h3>          
            <p> - Bulgarian (fluent)         <br></br>   - English (B2)</p>
            
            <h3> Social skills and competencies:</h3> 
            <p>
           <b> - Teamwork:</b> I’ve participated in different kinds of teams without any problems.<br></br>
           <b> - Communication skills:</b> I think I can communicate well with different kinds of people.<br></br>
           <b> - Organizational skills:</b> I’m an organized person who can help with events or even plan one.<br></br>
            </p>


</div>
    )
}