import React, { useCallback } from 'react';
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import {Routes, Route} from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.css';
import particlesOptions from "./particles.json";

import Header from "./components/Header";
import Footer from "./components/Footer";
import CV from "./pages/CV";
import About from "./pages/About";
import Projects from "./pages/Projects";

function App() {
    const particlesInit = useCallback(main => {
        loadFull(main);
    }, [])

    return (

        <Router>
                <Particles options={particlesOptions} init={particlesInit}/>
                
            <Header />

                <img className="sunReal" src="sun.png" alt="sun"/>

                <Routes>
                <Route  path="/" element={<CV />} />
                <Route exact path="/projects" element={<Projects />} />
                <Route exact path="/about" element={<About />} />
                </Routes>

             <Footer />
                
        </Router>

    );
}

export default App;
