import React from "react";



export default function BigProjects() {




    return(
<div >

<div style={{display:"flex"}} className="course1">
    <div className="morePadding">
        <h3>ERC-20 Bridge</h3> 
        <p>A bridge that transfers tokens from one Ethereum Testnet to another, using Solidity and React.</p>
        <li><b>Smart Contracts</b> <a href="https://github.com/SamBorisov/Bridge" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
        <p>Bridge.sol - includes functions for Locking, Unlocking, Minting and Burning tokens, as well as events for each function</p>
        <p>Wrap.sol - wrapping function that takes the data from token on the one network and copies the symbol, name<br></br> and 
            maps the address of the token to the original token so there won't be new wrapped tokens for the same token</p>
        <p>TestToken.sol - a normal ERC20 token for testing the whole process</p>
        <li><b>JavaScript</b></li>
        <p>Tests for the bridge written in JS</p>
        <p>Scripts for deploying the bridge contract on the testnet and mainnet</p>
        <li><b>Front End</b> <a href="https://github.com/SamBorisov/Bridge-UI" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a> </li>
        <p>Balance component - for showing the balance of the user</p>
        <p>TransactionModal - for creating a transaction </p>
        <p>TokenTransListModal - list with the transactions </p>
        <p>TokenTransDetailModal - details for the transactions </p>
        <p>Other components - used with template with some pre-existing components: <br /> button, connection with wallet and more</p>
        <p>Contracts - folder with the ABIs and necessary keys for Infura or EtherscanProvider </p>
        <li><b>What could be better?</b> <a href="https://github.com/SamBorisov/Bridge/tree/Bridge2" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
        <p>The current branch doesn't have any validation and it's quite centralized!</p>
        <p>Bridge has a second branch with good documentation, it is also working with Observers and Defenders! This is an entirely new bridge that's more secure. There are links
        for the Observer/Defender and the Front-End that are built by others, working as a team!</p>
        <li><b>Technology used</b></li>
        <p>Solidity, OpenZeppelin, Hardhat, Node.js, React, Ethers and more </p>
    </div>

    <img style={{textAlign:"right",maxWidth:"400px"}} src="tech/bridge.png" alt="brdige"/>
    </div>

    <div style={{display:"flex"}} className="course2">

    <img  style={{paddingLeft:"20%",paddingTop:"6%",maxHeight:"500px"}} src="tech/nft-marketplace.png" alt="nft-Marketplace"/>

    <div className="morePadding">
        <h3>NFT Marketplace <a href="https://github.com/SamBorisov/NFT-MarketPlace-IC" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></h3>
        <p>A marketplace where you can mint, list, sell, or buy NFTs on the IC network</p>
        <li><b>Smart Contracts</b></li>
        <p>main.mo - minting NFT, listing it and buying, query functions for information about if the NFT is listed<br></br> and who is the 1st owner
        using HashMaps to track and save the information <br /> about NFTs/Owners and Listings, and custom-type Listing </p>
        <p>nft.mo - few query functions to return the name/owner/image of the NFT and transfer ownership functionality</p>
        <li><b>Front End</b></li>
        <p>Using the contracts from the declarations folder, after they are deployed</p>
        <p>Minter.jsx - for minting your own NFT from an image you already have</p>
        <p>Gallery.jsx - mapping through the items(NFTs) and listing them</p>
        <p>Items.jsx - loading each NFT with owner/price(if on sale)/buy & sell button</p>
        <li><b>What could be better?</b></li>
        <p>When putting the NFT on the market, you transfer ownership to the MarketPlace contract, <br></br>
        if I want to cancel the NFT for listing or change the price, it is not possible</p>
        <li><b>Technology used</b></li>
        <p>DFX, Motoko, React</p>

    </div>
    </div>

    

<div style={{display:"flex"}} className="course1">

    <div>
        <h3>Web3 Registration <a href="https://web3-registration-fe.pages.dev/" target="_blank" rel="noreferrer"><i class="fa fa-globe"></i></a></h3>
        <p>Connecting wallet, the user can make registration with it using other data and then log in with a signature<br />
        the user can see their profile details and delete their profile, all incoming data is verified</p>
        <li><b>Back End</b> <a href="https://github.com/SamBorisov/-Web3-Registration-BE" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a> </li>
        <p>app.js - importing all necessary files packages and routes, setting up the server</p>
        <p>user.js - a model for a user, using mongoose</p>
        <p>routs - folder with registration/login/logout/delete/profile files <br />
        user login with a wallet signature and then a JWT token is sent to the local storage</p>
        <li><b>Front End</b> <a href="https://github.com/SamBorisov/Web3-Registration-FE" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a> </li>
        <p>Login.jsx - handle login with signature</p>
        <p>Profile.jsx - display user data if logged, says message if not (has also logout button)</p>
        <p>Register.jsx - register a user with from and if this address has registration, there's is delete function</p>
        <li><b>What could be better?</b></li>
        <p>Main functionality is missing, it's an open project with wallet registration and login, that could be transformed into: <br />
        social app, blog, to-do, or any other site that requires registration.</p>
        <li><b>Technology used</b></li>
        <p>Node.js React Express Mongoose Web3Onborard Ethers JWT sigUtil</p>
    </div>

    <img style={{maxHeight:"450px",paddingTop:"7%"}} src="tech/web3.png" alt="web3"/>

    </div>

    <div style={{display:"flex"}} className="course2">

    <img  style={{paddingLeft:"20%",paddingTop:"6%",maxHeight:"500px"}} src="tech/ticket.gif" alt="NTF ticket"/>

    <div className="morePadding" style={{paddingLeft:"10%"}}>
        <h3>Event Factory with ERC1155 tickets</h3>
        <p>Contract Factory that has a function for creating an even contract, with many functionalities</p>
        <li><b>Smart Contracts</b> <a href="https://github.com/SamBorisov/Events" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a> </li>
        <p>Marketplace.sol - a proxy factory that deploys a new event contract with specific details <br></br> and sets the sender of the function as the owner of the new contract. </p>
        <p>Event.sol -  saves event details, allows users to buy tickets at a specific price,<br></br> choose a lucky winner at the end of the event, and allows the owner to withdraw their profit from the sale.</p>
        <li><b>Front End</b> <a href="https://github.com/SamBorisov/event-fe" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a> </li>
        <p>Using the contracts with importing abis and saving addresses, after they are deployed</p>
        <p>App.jsx - for wallet integration , buy tickets fuctionality and check ticket balance <br></br> (import abi and have the contract address there)</p>
        <p>Button.jsx - helper button component</p>
        <p>Event.jsx - fetches the nessesery details about the event and render them</p>
        <p>Timer.jsx - Fetches the current timestamp and he end of the tickets sale <br></br> (see them by clicking on the btn)</p>
        <p>LuckyWinner.jsx - fetch to see if there is one (disable the btn "see lucky winner" if not any),<br></br> and functions to get the lucky winner (after event ends) and see his adress after it was chosen</p>
        <li><b>What could be better?</b></li>
        <p>The ERC1155 token is not using an ID property, which makes it hard to track the NFTs <br></br> if they are sold later or send to someone else, they are all the same!</p>
        <li><b>Technology used</b></li>
        <p>Solidity, OpenZeppelin, Hardhat, Node.js, React, Ethers, Web3Onborard and more</p>

    </div>
    </div>

</div>
    )
}
