import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";

const serviceID = process.env.REACT_APP_YOUR_SERVICE_ID;
const templateID = process.env.REACT_APP_YOUR_TEMPLATE_ID;
const publicKey = process.env.REACT_APP_YOUR_PUBLIC_KEY;

export default function About() {
  const form = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(serviceID, templateID, form.current, publicKey).then(
      (result) => {
        console.log(result.text);
        alert("Message send");
        form.current.reset();
      },
      (error) => {
        console.log(error.text);
        alert(error.text);
      }
    );
  };

  return (
    <div>
      <div style={{ maxWidth: "1100px", margin: "auto", textAlign: "left" }}>
        <div style={{ display: "flex" }}>
          <div>
            <h1>About me:</h1>
            <p>
              {" "}
              <b>
                {" "}
                My English name is Samuel and most people call me Sam! <br />
                I am currently 25 years old. From starting to spend a lot of
                time as a kid on my PC, then moving to Technical School, and
                then University shows that all my life I have been around
                technology. As I was little, I was always curious about how
                electronic devices work (phones, computers, TVs) and I thought
                it was some kind of magic. That's how I started researching and
                learning about them. <br />
              </b>
            </p>
            <h4>Goals</h4>
            <p>
              {" "}
              <b>
                {" "}
                New technologies, making something look beautiful, and doing
                meaningful projects always give me motivation. My goals are to
                become a Professional at a specific area, work on big projects
                and even coach new people in the space. Having these kinds of
                skills and doing remote work with flexible working hours would
                be amazing, however, I am aware that it will take time.
              </b>
            </p>
            <h4>Hobbies</h4>
            <p>
              {" "}
              <b>
                {" "}
                Outside this Tech space, I am also interested in a lot of other
                things:
                <li> Investing and Finances</li>
                <li>
                  {" "}
                  Sports - Diving, Skydiving, MartialArts, Swimming and more
                </li>
                <li>
                  {" "}
                  Traveling - living as a digital nomad, learning cultures
                </li>
                <li>
                  {" "}
                  Health lifestyle - moving more during work, eating better
                </li>
                <li> Personal growth & Wellbeing</li>
                <li> Planet, environment, and a better life for everyone</li>
              </b>
            </p>
          </div>

          <img
            style={{ height: "100%", paddingTop: "13%", borderRadius: "60%" }}
            src="profilee.png"
            alt="Me"
          />
        </div>

        {/* <h3>
          I am also working in a personal blog, that's focused on building healthier work-style! <a href="https://healthierworkstyle.com/" target="_blank" rel="noreferrer"><i class="fa fa-globe"></i></a>
          </h3> */}
        <img
          className="sun"
          style={{ width: "450px", paddingLeft: "35%", marginTop: "15%" }}
          src="galaxy.png"
          alt="Galaxy"
        />
      </div>

      <h2 style={{ paddingTop: "7%" }}>Contact me</h2>
      <StyledContactForm>
        <form ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <br />
          <input type="text" name="user_name" />
          <label>Email</label>
          <br />
          <input type="email" name="user_email" />
          <label>Message</label>
          <br />
          <textarea name="message" />
          <input type="submit" value="Send" />
        </form>
      </StyledContactForm>
    </div>
  );
}

const StyledContactForm = styled.div`
  width: 400px;
  margin: 0 auto;
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid orange;
      background: transparent;
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid orange;
      background: transparent;
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      cursor: pointer;
      background: rgb(249, 105, 14);
      color: white;
      border: none;
      text-align: center;
      justify-content: center;
      margin-top: 2rem;
      margin-left: 0.4rem;
      color: black;
      bold: 700;
      font-weight: bold; /* Makes the text bold */
    }
  }
`;
