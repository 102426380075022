import React from "react";



export default function ReactT() {




    return(
<div className="course1" style={{display:"flex"}}>

    <div>

        <h3>React</h3>
        <li><b>React Vite</b> <a href="https://github.com/SamBorisov/React_vite" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
             <p>Projects with APIs that get information from different sites</p>
        <li><b>Travel cards</b> <a href="https://github.com/SamBorisov/TravelReact" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
             <p>Using props and maps to read data and display it on cards</p>
        <li><b>React Inline</b> <a href="https://github.com/SamBorisov/ReactInline" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
              <p>One page with different tasks using: useState & conditional rendering</p>
        <li><b>React Challenges</b> <a href="https://github.com/SamBorisov/React-MiniChallanges" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
            <p>Props, Map, conditional rendering, Forms, useState and more </p>
        <li><b>Blockchain</b> <i class="fa fa-bitcoin"></i></li>
            <p>To-do IC, DBank IC, React Web3 use also React as Front End</p> 
        <li><b>Google Keeper copy</b> <a href="https://github.com/SamBorisov/GKeeper---React" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
            <p>To-do app, copy of google keeper</p> 
        <li><b>Spa Call center</b> <a href="https://github.com/SamBorisov/spa-task" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
            <p>Task with hovering animations, data rendering and more </p> 
            </div>

            <div style={{paddingLeft:"9%"}}>
            <img className="" src="tech/reactIco.png" alt="React"/>
            </div>

</div>
    )
}