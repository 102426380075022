import React from "react";

import Experience from "../components/Cv/Experience";
import Education from "../components/Cv/Education";
import Courses from "../components/Cv/Courses";
import PersonalSkills from "../components/Cv/PersonalSkills";
import Awards from "../components/Cv/Awards";

export default function CV() {




    return(
<div>
         <h1 className="morePadding">Curriculum Vitae</h1>

    <div style={{display:"flex"}}>

        <img className="profileImg" src="profile1.png" alt="Samuil Borisov"/>

        <div className="personalInf">
            <h2>Personal Information</h2>
            <p><b>Name:</b> Samuil Aleksandrov Borisov</p>
            <p><b>Location:</b> Sofia, Bulgaria</p>
            <p><b>Email:</b> samuil@encorp.io</p>
            <p><b>LinkedIn:</b> <a href="https://www.linkedin.com/in/samuil-borisov-a350aa220/" target="_blank" rel="noreferrer">Profile</a></p>
            <p><b>Git:</b> <a href="https://github.com/SamBorisov" target="_blank" rel="noreferrer">SamBorisov</a> </p>
        </div>

    </div>

        <h2>Professional Experience</h2>

        <Experience />

        <h2>Courses & Traning programs</h2>

        <Courses />

        <h2>Education</h2>

        <Education />

        <h2 className="morePadding">Personal Skills and Competences</h2>

        <PersonalSkills />

        <h2 className="morePadding">Awards, Honors and Certificates</h2>

        <Awards />


</div>
    )
}
