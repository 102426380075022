import React from "react";



export default function Courses() {




    return(
        <div>

                 <img className="sun" src="Venus2.png" alt="Venus"/>

<div className="course1">

        <h3><a href="https://www.udemy.com/course/the-complete-web-development-bootcamp/" target="_blank" rel="noreferrer">The Complete Web Development Bootcamp 2023</a> <span>(11.2022-02.2023)</span></h3>
        <h4>Udemy (bootcamp-student)</h4>
        <h5>Bootstrap, Node JS, Git, React, jQuery, MongoDB, Motoko, ICP</h5>
        <ul>
            <li>Cover of HTLM, CSS, Bootstrap methods, UI & UX design patterns</li>
            <li>Making logical tasks on ES6 and jQuery (small projects)</li>
            <li>Using Express, Mongoose & EJS for a blog site, REST APIs, Authentication</li>
            <li>Google Keeper clone app with React & Mongoose</li>
            <li>Build an NFT marketplace on ICP with Motoko and React</li>
        </ul>

</div>
<div className="course2">

        <h3><a href="https://limeacademy.tech/" target="_blank" rel="noreferrer">LimeAcademy</a> <span>(03.2022 - 06.2022)</span></h3>
        <h4>Trainee developer (academy-student)</h4>
        <h5>Solidity, Node JS, Git, React, Slack, Hardhat, Ethers</h5>
        <ul className="listRight">
            <li>Learn deeply about smart contracts, blockchain and Web3</li>
            <li>Make little tasks for a better understanding of the whole process</li>
            <li>Covering Hardhat Ethers.js OpenZeppelin and more</li>
            <li>Develop an Ethereum bridge between 2 test nets with Solidity and React</li>
            <li>Received an NFT-graduate certificate for the successful project</li>
        </ul>

</div>
</div>
    )
}
