import React from "react";



export default function Node() {




    return(
<div className="course1" style={{display:"flex"}}>



    <div style={{marginRight:"10%"}}>
        <h3>Express, Mongoose, EJS, Bootstrap</h3>
        <li><b>Front End</b> <a href="https://github.com/SamBorisov/Front-end" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
             <p>Dice Game, Drum Kit, Profile page, TinDog(Tinder Copy for dogs), jQuery</p>
        <li><b>ES6 practice</b> <a href="https://github.com/SamBorisov/JavaScript-Projects" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
             <p>BMI Calculator, Newsletter, Simon Game, Fruit Project(Mongoose), Name convertors, Fizz Buzz game, Fibonacci and more</p>
        <li><b>APIs</b> <a href="https://github.com/SamBorisov/APIs" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
              <p>API that gets the weather, REST API for articles with Mongoose, simple REST API </p>
        <li><b>Authentication</b> <a href="https://github.com/SamBorisov/Authentication-Node.js" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
            <p>.env, password  hashing, salting, encrypting passwords, cookies & sessions " bctrypt, passport, md5" (npm)</p>
        <li><b>To-Do</b> <a href="https://github.com/SamBorisov/To-Do-List" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
            <p>To-Do app that creates custom  todo pages with EJS & Mongoose </p>
        <li><b>Blog Site</b> <a href="https://github.com/SamBorisov/Blog-Site" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></li>
            <p>A blog page that allows users to create/edit/delete posts and show them. With EJS and Mongoose</p>

    </div>

        <div style={{paddingLeft:"9%"}}>
            <img className="tech" src="tech/JS (1).png" alt="jQuery"/>
            <img className="tech" src="tech/JS (2).png" alt="API"/>
            <img className="tech" src="tech/JS (3).png" alt="Node.js"/>
            <img className="tech" src="tech/JS (4).png" alt="ES6"/>
            <img className="tech" src="tech/JS (5).png" alt="JS"/>
        </div>
</div>
    )
}
