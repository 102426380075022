import React from "react";



export default function Experience() {




    return(
        <div>
             <img className="sun" src="Mercury1.png" alt="Mercury"/>

             <div className="course2">

                <h3><a href="https://hydrachain.org/" target="_blank" rel="noreferrer">HydraChain (via Encorp)</a> <span>(04/2024 - Ongoing)</span></h3>
                <h4>Blockchain Developer (full-time)</h4>
                <h5>Solidity, Hardhat, Next.js, Slack, React, Git-project, Foundry.</h5>
                <ul className="listRight">
                    <li>Work on HydraGon core contracts</li>
                    <li>Making tests, scripts, and debugging contracts</li>
                    <li>Working on Next.js front end</li>
                    <li>Interact with contracts from the front end</li>
                </ul>

            </div>

<div className="course1">

        <h3><a href="https://www.tokacity.com/" target="_blank" rel="noreferrer">TokaCity (via Encorp)</a> <span>(07/2023 - 04/2024)</span></h3>
        <h4>Smart Contract Developer & Full Stack Developer (full-time)</h4>
        <h5>Solidity, Git, Slack, Jira, Hardhat, Nest.js, React, Docker, pgAdmin</h5>
        <ul>
            <li>Creating a swap token contract & more</li>
            <li>Making tests, scripts, and debugging contracts</li>
            <li>Interacting with contracts from the front end</li>
            <li>Making design to fit all types of screens</li>
            <li>Working with Nest.js backend</li>
        </ul>

</div>
    <div className="course2">

        <h3><a href="https://www.encorp.io/" target="_blank" rel="noreferrer">Encorp io</a> <span>(07/2023 - Ongoing)</span></h3>
        <h4>Blockchain Developer (full-time)</h4>
        <h5>Solidity, Remix IDE, Git, Slack, Zaho, Hardhat, Nest.js, React</h5>
        <ul className="listRight">
            <li>Develop Smart contracts using Solidity, Hardhat & OpenZeppelin</li>
            <li>Making tests, scripts, and debugging contracts</li>
            <li>Working on big projects like TokaCity casino</li>
            <li>Helping with front-end & back-end (React/Nest.js)</li>
        </ul>

    </div>

<div className="course1">

        <h3><a href="http://www.csd-bg.com/" target="_blank" rel="noreferrer">CSD Ltd</a> <span>(10/2022 - 06/2023)</span></h3>
        <h4>Developer (full-time)</h4>
        <h5>DevOps, Microsoft Teams, Microsoft Azure, Slack, Node.js, React</h5>
        <ul>
            <li>Working with Microsoft team Apps</li>
            <li>Microsoft Teams toolkit & Microsoft/teams-js</li>
            <li>Using React and API calls for the Apps</li>
            <li>NATS Messaging on Node.js</li>
        </ul>

</div>
<div className="course2">

        <h3><a href="http://www.csd-bg.com/" target="_blank" rel="noreferrer">CSD Ltd</a> <span>(05/2021 - 10/2021)</span></h3>
        <h4>Intern (part-time)</h4>
        <h5>Solidity, Remix IDE, Git, Microsoft Teams, Microsoft SQL, Microsoft Azure, Slack</h5>
        <ul className="listRight">
            <li>Research smart contracts and blockchain technologies</li>
            <li>Learn more about Solidity and Ethereum specifically</li>
            <li>Development of token and put it on test server Kovan Testnet</li>
            <li>Work with Microsoft SQL & Microsoft Azure</li>
        </ul>

</div>
<div className="course1">

        <h3><a href="http://www.sibiz.net/" target="_blank" rel="noreferrer">Sibiz Bulgaria Ltd</a> <span>(07/2020 - 12/2020)</span></h3>
        <h4>Verification operator (full-time)</h4>
        <h5>Verilog, System Verilog, UVM, Linux, AnyDesk</h5>
        <ul>
            <li>Develop FIFO, Latch, and other tasks on Verilog & make test-benches</li>
            <li>Develop FIFO on System Verilog & UVM</li>
            <li>Learn about the verification process</li>
            <li>Read Data-books and Test chipsets</li>
        </ul>

</div>
<div className="course2">

        <h3><a href="https://www.viscomp.bg/#landing" target="_blank" rel="noreferrer">Viscom BG</a> <span>(07/2017)</span></h3>
        <h4>Front End developer - Intern</h4>
        <h5>HTML, CSS, Notepad++</h5>
        <ul className="listRight1">
            <li>Practicing HTML, CSS and styling</li>
            <li>Developed frontend design of a shopping site</li>
        </ul>

</div>
</div>
    )
}
