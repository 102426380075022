import React from "react";



export default function Awards() {




    return(
<div>

     <img className="sun" src="Jupiter5.png" alt="Jupiter"/>

          <div style={{paddingTop:"3%"}}>

            <li><b>Udemy Certified - The Complete 2023 Web Development Bootcamp</b> <a href="https://www.udemy.com/certificate/UC-c0351d4d-60ec-45c9-9805-a59b9399de7d/" target="_blank" rel="noreferrer">
               <i class="fa fa-certificate"></i></a></li>
                 <p>Completed the course on 02.2023</p>

            <li><b>SEO Certified - HubSpot Academy</b> <a href="https://app-eu1.hubspot.com/academy/achievements/rysml18g/en/1/samuil-borisov/seo" target="_blank" rel="noreferrer">
               <i class="fa fa-certificate"></i></a></li>
                 <p>Completed the course on 21.10.2022</p>

            <li><b>Entrepreneurial and Skillful Youth (E.A.S.Y) in Aksaray, Turkey</b> <i class="fa-sharp fa-regular fa-circle-dot"></i></li>
                 <p>From 14.08.2022 to 20.08.2022</p>

            <li><b>Center of Humane Technology – “Foundations of Humane Technology”</b> <a href="https://www.credential.net/289d7908-db24-4f2a-96b9-ea1bf460419a#gs.qa8rwn" target="_blank" rel="noreferrer">
               <i class="fa fa-certificate"></i></a></li>
                  <p>Completed the course on 27.07.2022</p>

            <li><b>LimeAcademy Season 2 Graduate – develop an ERC-20 bridge successfully</b> <a href="https://app.poap.xyz/token/5033875" target="_blank" rel="noreferrer">
               <i class="fa fa-certificate"></i></a></li>
                  <p>From 03.2022 to 06.2022</p>

            <li><b>Erasmus+, Project in Chez Republic</b> <i class="fa-sharp fa-regular fa-circle-dot"></i></li>
                 <p>From 5.03.2018 to 23.03.2018</p>

          </div>

</div>
    )
}
