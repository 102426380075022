import React from "react";
import BigProjects from "../components/Projects/BigProjects";
import Blockchain from "../components/Projects/Blockchain";
import Node from "../components/Projects/Node";
import ReactT from "../components/Projects/React";



export default function Projects() {




    return(
        <div>

        <h1>Summary:</h1>
        <h4>Languages: </h4> <p>JavaScript, Solidity, Motoko </p>
        <h4>Technologies: </h4> <p>Blockchain, React, APIs, Node.js</p>
        <h4>Libraries: </h4> <p>jQuery, Bootstrap, Hardhat, Ethers, DFX</p> 


        <h2>Node.js</h2>

        <img style={{width:"400px"}} className="planet" src="Saturn6.png" alt="Saturn"/>

        <Node />


        <h2 className="morePadding">Blockchain</h2>

        <img className="planet" src="Uranus7.png" alt="Uranus"/>

        <Blockchain />

        <h2 style={{paddingBottom:"3%"}}>React</h2>

        <img className="planet" src="Neptune8.png" alt="Neptune"/>

        <ReactT />

        <h2>Bigger Projects</h2>

        <img className="planet" src="Pluto9.png" alt="Pluto"/>

        <BigProjects />

        </div>
    )
}