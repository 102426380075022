import React from "react";



export default function Education() {




    return(
        <div>

            <img className="sun" style={{width:"400px"}} src="Earth3.png" alt="Earth"/>

        <div className="course1">

        <h3><a href="https://www.tu-plovdiv.bg/en/aboutus.php" target="_blank" rel="noreferrer">Sofia Technical University - Branch Plovdiv</a> <span>(09.2018 – 07.2022)</span></h3>
        <h4>Bachelor, Engineer: Computer systems and technologies</h4>
        <p><i>Subjects/occupational skills covered:</i><br></br> Databases, algorithms, object-oriented programming,<br></br>
        computer architecture, C programming & more</p>


        </div>
        <div className="course2">

                <h3><a href="https://pgee-plovdiv.com/" target="_blank" rel="noreferrer">Professional School of Electronics, Plovdiv</a> <span>(09.2013 - 07.2018)</span></h3>
                <h4>Specialty: Computer equipment and technologies</h4>
                <p className="listRight1"><i>Subjects/occupational skills covered:</i><br></br> Processors and memory, information technology,
        motherboards and peripheral devices, C++ programming & more</p>


        </div>
</div>
    )
}